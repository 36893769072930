import React from 'react';
import { Select } from 'antd';
import { useDebounce } from 'react-use';
import { LoadingOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';

import agent from '~/stores/Agent';
import useResponsive from '~/hooks/useResponsive';

const Loading = () => (
  <>
    <div className="loading">
      <LoadingOutlined style={{ fontSize: 12 }} spin />
      <span>正在查询...</span>
    </div>
    <style jsx>{`
      .loading {
        padding-left: 12px;
        padding-bottom: 5px;
        border-bottom: 1px solid var(--white-two);

        span {
          margin-left: 8px;
        }
      }
    `}</style>
  </>
);

const SchoolNameSelect = ({ OptionWrapper = React.Fragment, includeCN = 0, ...rest }) => {
  const [options, setOptions] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [value, setValue] = React.useState();
  const { mobile } = useResponsive();

  const search = async v => {
    if (v != null) {
      const res = await agent.Colleges.search(v, includeCN);
      setOptions(res?.data || []);
      setLoading(false);
    }
  };

  useDebounce(() => search(value), 500, [value]);

  const handleSearch = v => {
    setLoading(true);
    setValue(v);
  };

  const handleFocus = () => {
    search('');
  };

  const dropdownRender = menu => {
    return (
      <>
        {loading && <Loading />}
        {menu}
      </>
    );
  };

  return (
    <div>
      <Select
        showSearch
        allowClear
        filterOption={false}
        dropdownRender={dropdownRender}
        onSearch={handleSearch}
        onFocus={handleFocus}
        dropdownMatchSelectWidth={mobile}
        optionLabelProp="label"
        {...rest}>
        {options.map(option => {
          const dashSchoolName = option.school_name ? `-${option.school_name}` : '';
          return (
            <Select.Option
              key={option.short_name}
              value={option.short_name}
              label={`${option.short_name} ${option.school_name}`}>
              <OptionWrapper>
                <div className="option">
                  <div>
                    <Highlighter
                      searchWords={[value]}
                      textToHighlight={mobile ? option.short_name : `${option.short_name}${dashSchoolName}`}
                      highlightStyle={{ fontWeight: 'bold', background: 'none', padding: '0px' }}
                      unhighlightStyle={{ color: 'var(--black)' }}
                    />
                    <div className="mute">{option.school_name_cn}</div>
                  </div>
                  <div className="flag">{option.area}</div>
                </div>
              </OptionWrapper>
            </Select.Option>
          );
        })}
      </Select>
      <style jsx>{`
        .mute {
          font-size: 12px;
          color: var(--greyish-brown);
        }
        .option {
          display: flex;
          justify-content: space-between;
        }
        .flag {
          align-self: center;
          color: initial;
          margin-right: 5px;
        }
      `}</style>
    </div>
  );
};

export default SchoolNameSelect;
