import MajorList from '~/data/major.json';
import { chartUndertier } from '~/utils/mapping';

export function toCollegeRelated(college) {
  return {
    image: college.logo_image,
    shortName: college.short_name,
    name: college.school_name,
    nameCN: college.school_name_cn,
    area: college.area,
    link: `/university/${college.short_name}`,
  };
}

export function toProgramRelated(program) {
  return {
    image: program.college_info.logo_image,
    shortName: program.program_name,
    name: program.program_full_name,
    nameCN: program.college_info.school_name_cn,
    area: program.college_info.area,
    link: `/program/${program.seo_url}`,
    program,
  };
}

export function toCollegeSchoolRelated(collegeSchool) {
  return {
    image: collegeSchool.college_info.logo_image,
    shortName: collegeSchool.college_shortname,
    name: collegeSchool.name,
    nameCN: collegeSchool.college_info.school_name_cn,
    area: collegeSchool.college_info.area,
    link: `/grad-schools-depts/${collegeSchool.seo_url}`,
  };
}

const undertierOrder = ['海本', '清北科', '南大/浙大/复旦/上交', 'Top15', 'Top30', '其他985/211', '双非'];

export function sortChartAxis(data) {
  if (data[0][0].includes('-')) {
    return data.sort(([a], [b]) => {
      return Number(a.split('-')[0]) - Number(b.split('-')[0]);
    });
  }
  if (undertierOrder.includes(data[0][0])) {
    return data.sort(([a], [b]) => {
      return undertierOrder.indexOf(b) - undertierOrder.indexOf(a);
    });
  }
  return data;
}

export function toChartData(data) {
  const entries = Object.entries(data);
  const defaultValue = { category: [], rej: [], ad: [] };
  if (entries.length) {
    return sortChartAxis(entries).reduce((r, v) => {
      r['category'].push(v[0]);
      r['rej'].push(-v[1].rej);
      r['ad'].push(v[1].ad);
      return r;
    }, defaultValue);
  }
  return defaultValue;
}

export function toFilters(data, defaultKeys = {}, toString) {
  const keys = {
    college: 'college_name',
    program: 'program_id',
    'college-school': 'college_school_id',
    major: 'major',
    ...defaultKeys,
  };
  let filters = {};
  if (data.type === 'major') {
    filters = {
      [keys['major']]: data.id,
    };
    if (data.college) {
      filters[keys['college']] = data.college.short_name;
    }
  } else {
    filters = {
      [keys[data.type]]: data.id,
    };
  }
  return toString ? new URLSearchParams(filters).toString() : filters;
}

export const filterFieldC = {
  college: 'outname',
  program: 'program_id',
  'college-school': 'college_school_id',
  major: 'planmajor',
};

export const typeText = {
  college: '学校',
  program: '项目',
  'college-school': '学院',
  major: '专业',
};

export function getResultColor(value) {
  return (
    {
      Reject: '#CB4141',
      WaitingList: '#FC9F4A',
    }[value] || '#18A00F'
  );
}

export function getMajor(id) {
  return MajorList.find(v => v.key === id);
}

export function getMajorByShortName(shortName) {
  return MajorList.find(v => v.shortName === shortName);
}

export function getUrlId(seoUrl) {
  if (seoUrl.includes('-')) {
    return seoUrl.split('-').reverse()[0];
  }
  return seoUrl;
}

export function getCategoryAxisValue(categories, value) {
  if (Number.isNaN(Number(value))) {
    return categories.find(v => v === value);
  }
  return categories.find(v => {
    const [min, max] = v.split('-');
    return value >= Number(min) && value <= Number(max);
  });
}

export function getCharMineValues(background) {
  return {
    GPA: background?.undergpa?.split('|')[0],
    GRE: background?.g?.split('|')[0],
    TOEFL: background?.t?.split('|')[0],
    本科学校: chartUndertier?.[background?.undertier],
  };
}
