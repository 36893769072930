import { Button, message } from 'antd';
import { StarFilled, StarOutlined } from '@ant-design/icons';

import { User, Favorites } from '~/services/agent';

export default function FavoriteButton({ className, type, id }) {
  const { user, isLogin, refetch } = User.getMyProfile();
  const favorites = user?.favorites?.[type] || [];

  const isFavite = favorites.some(v => String(v).toLowerCase() === String(id).toLowerCase());

  async function handleClick() {
    if (!isLogin) {
      message.error('请先登录');
    } else {
      await (isFavite ? Favorites.delete : Favorites.add)(id, type);
      refetch();
    }
  }

  return (
    <Button
      onClick={handleClick}
      className={`flex space-x-1 items-center rounded-lg bg-#FAFAFA border border-[#E0E0E0] ${className}`}>
      {isFavite ? (
        <>
          <StarFilled />
          <span>已关注</span>
        </>
      ) : (
        <>
          <StarOutlined />
          <span>关注</span>
        </>
      )}
    </Button>
  );
}
