import React from 'react';
import { Select } from '~/components/antd';
import MajorList from '~/data/major.json';

const PlanMajorSelect = ({ ...rest }) => {
  return (
    <Select
      allowClear
      showSearch
      optionLabelProp="text"
      placeholder="请选择专业"
      {...rest}
      filterOption={(inputValue, option) => {
        return (
          option.props.name.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1 ||
          option.props.text.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
        );
      }}>
      {MajorList.map(item => (
        <Select.Option key={item.shortName} value={item.key} text={item.shortName} name={item.name}>
          <h4>{item.shortName}</h4>
          <h5>{item.name}</h5>
        </Select.Option>
      ))}
    </Select>
  );
};

export default PlanMajorSelect;
