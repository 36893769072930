import React from 'react';
import Link from 'next/link';

const data = [
  {
    title: '申请准备',
    textColor: '#497b89',
    bgColor: '#dfedf1',
    link: '/my/preparations',
    items: [
      { title: '英语考试', link: '' },
      { title: '申请文书', link: '' },
      { title: '关注学校', link: '' },
      { title: '关注项目', link: '' },
    ],
  },
  {
    title: '定位选校',
    textColor: '#21A318',
    bgColor: '#EAF6E6',
    link: '/my/targets',
    items: [
      { title: '定位评估', link: '/evaluation' },
      { title: '智能选校', link: '/my/recommend' },
      { title: '我的选校', link: '' },
      { title: '招生信息', link: '' },
    ],
  },
  {
    title: '申请进展',
    textColor: '#F1A510',
    bgColor: '#FFEFAF',
    link: '/my/applications',
    items: [
      { title: '网申交流', link: '' },
      { title: '话题讨论', link: '' },
      { title: '进度管理', link: '' },
      { title: '结果汇报', link: '' },
    ],
  },
  {
    title: '比较决定',
    textColor: '#BF7400',
    bgColor: '#FFD19B',
    link: '/my/decision',
    items: [
      {
        title: (
          <>
            Offer
            <br className="md:hidden" />
            对比
          </>
        ),
        link: '',
      },
      { title: '择校分析', link: '' },
      { title: '信息征集', link: '' },
      { title: '申请总结', link: '' },
    ],
  },
  {
    title: '飞跃重洋',
    textColor: '#B82E0F',
    bgColor: '#FFB7A7',
    link: '/my/travel',
    clipPath: 'polygon(0 25%, 85% 25%, 85% 0, 100% 50%, 85% 100%, 85% 75%, 0 75%)',
    clipPathMobile: 'polygon(20% 0, 20% 80%, 0 80%, 50% 100%, 100% 80%, 77% 80%, 77% 0)',
    items: [
      { title: '签证手续', link: '' },
      { title: '飞跃手册', link: '' },
      { title: '找新生群', link: '' },
      { title: '留学生活', link: '' },
    ],
  },
];

export default function NavItems() {
  return (
    <ul className="md:max-w-6xl md:mx-auto flex flex-col md:flex-row justify-between md:space-x-4 space-y-6 md:space-y-0">
      {data.map(({ title, textColor, bgColor, items, link }, idx) => (
        <li key={title} className=" md:w-[180px] h-[50px] md:h-[272px] md:rounded-tl-3xl md:rounded-br-3xl md:shadow-1">
          <Link href={link}>
            <a className="w-full">
              <div className="w-full flex-1 flex md:flex-col md:mt-8 md:mb-3 h-full">
                <div
                  className=" md:hidden  text-xxs font-bold transform rotate-180 flex justify-center"
                  style={{ color: textColor, writingMode: 'tb-rl' }}>
                  STEP{idx + 1}
                </div>
                <strong
                  className=" relative text-xs md:text-lg h-full md:h-[36px] flex items-center justify-center md:justify-start pl-0 md:pl-5 mr-3 md:mr-0 w-10 md:w-auto"
                  style={{ color: textColor, background: bgColor }}>
                  <span className="hidden md:inline">{idx + 1}.</span>
                  <div className="">
                    {title.split('').map((v, i) => (
                      <React.Fragment key={v}>
                        <span>{v}</span>
                        {i === 1 && <br className="md:hidden" />}
                      </React.Fragment>
                    ))}
                  </div>
                  {idx === 4 && (
                    <div
                      className=" md:hidden absolute top-[50px] border-[30px] border-t-[20px] border-b-transparent border-l-transparent border-r-transparent"
                      style={{ borderTopColor: bgColor }}
                    />
                  )}
                </strong>
                <div className=" flex-1 md:flex-none flex md:flex-col divide-x md:divide-x-0 md:divide-y divide-#DCDCDC divide-dashed md:px-4 py-1 md:py-0  md:mt-3 text-xs md:text-sm bg-white shadow-1 md:shadow-none rounded-md md:rounded-none">
                  {items.map(({ title: itemTitle }) => (
                    <span
                      className="text-#5E5E5E pl-2 h-10 flex items-center justify-center md:justify-start flex-1 md:flex-none"
                      key={itemTitle}>
                      <div>
                        {typeof itemTitle === 'string'
                          ? itemTitle.split('').map((v, i) => (
                              <React.Fragment key={v}>
                                <span>{v}</span>
                                {i === 1 && <br className="md:hidden" />}
                              </React.Fragment>
                            ))
                          : itemTitle}
                      </div>
                    </span>
                  ))}
                </div>
              </div>
            </a>
          </Link>
        </li>
      ))}
    </ul>
  );
}
