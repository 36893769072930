import React from 'react';
import ReactEcharts from 'echarts-for-react';

import { getCategoryAxisValue } from '~/utils/University';

export default function BarChart({ title, value, mineValue, data }) {
  const [mineCoordinate, setMineCoordinate] = React.useState();
  const [avgCoordinate, setAvgCoordinate] = React.useState();
  const instance = React.useRef();

  const option = {
    tooltip: {
      trigger: 'axis',
      backgroundColor: 'rgba(94, 94, 94, 0.8)',
      borderWidth: 2,
      borderColor: '#DCDCDC',
      textStyle: {
        color: '#fff',
      },
      formatter([a]) {
        return `
            <div class="font-bold">${a.marker}${a.name}: ${a.value}%</div>
            `;
      },
    },
    grid: {
      top: 0,
      left: 5,
      right: 0,
      bottom: 0,
      containLabel: true,
    },
    xAxis: {
      type: 'value',
      axisLine: {
        show: false,
      },
      splitLine: {
        show: false,
      },
      axisLabel: {
        show: false,
      },
    },
    yAxis: {
      type: 'category',
      axisLine: {
        show: false,
      },
      splitLine: {
        show: true,
        lineStyle: {
          color: '#DCDCDC',
          type: 'dashed',
        },
      },
      axisLabel: {
        color: '#5E5E5E',
        fontSize: 10,
      },
      axisTick: {
        show: false,
      },
      data: data['category'],
    },
    series: [
      {
        type: 'bar',
        color: '#FCA049',
        barMaxWidth: 10,
        data: data['ad'],
      },
    ],
  };

  function handleChartReady(chart) {
    instance.current = chart;
    setAvgCoordinate(
      instance.current.convertToPixel({ xAxisIndex: 0, yAxisIndex: 0 }, [0, getCategoryAxisValue(data.category, value)])
    );
  }

  React.useEffect(() => {
    if (mineValue) {
      setMineCoordinate(
        instance.current.convertToPixel({ xAxisIndex: 0, yAxisIndex: 0 }, [
          0,
          getCategoryAxisValue(data.category, mineValue),
        ])
      );
    }
  }, [mineValue]);

  return (
    <div className="bg-#FAFAFA rounded-2xl border border-#DCDCDC p-3 text-#5E5E5E">
      <div className="font-bold">{title}</div>
      <div className="text-xl text-#18A00F">{value}</div>
      <div className=" relative">
        <ReactEcharts option={option} className="h-[171px] pt-2" onChartReady={handleChartReady} />
        {mineCoordinate && (
          <div
            className="absolute h-[1px] border-t border-dashed border-#18A00F text-#18A00F"
            style={{
              left: mineCoordinate[0],
              top: mineCoordinate[1] + 8,
              width: `calc(100% - ${mineCoordinate[0]}px)`,
            }}>
            <div className="absolute right-0 bottom-0">Mine</div>
          </div>
        )}
        {avgCoordinate && (
          <div
            className="absolute h-[1px] border-t border-dashed border-#18A00F text-#18A00F"
            style={{
              left: avgCoordinate[0],
              top: avgCoordinate[1] + 8,
              width: `calc(100% - ${avgCoordinate[0]}px)`,
            }}>
            <div className="absolute right-0 bottom-0 text-xxs">Avg</div>
          </div>
        )}
      </div>
    </div>
  );
}
