import Link from 'next/link';

export default function FAQBanner() {
  return (
    <div>
      <Link href="/db/faq">
        <a className="text-#5E5E5E">
          <div className="relative">
            <div className="relative flex max-w-[1140px] min-h-[380px] mx-auto z-30 md:pt-10 pb-16 md:pb-0">
              <div className="md:w-2/3 mx-6 lg:mx-0 pt-12">
                <div className="relative ml-3 w-64 md:w-[366px] h-10 bg-#FFECC9 rounded-full">
                  <h1 className="absolute -top-3 md:-top-6 -left-3 text-3xl md:text-[40px] text-#5E5E5E font-bold text-shadow">
                    申请指南电子书
                  </h1>
                </div>
                <div className="ml-4 md:ml-10">
                  <h2 className="mt-3 text-lg md:text-2xl text-primary font-bold flex flex-col md:flex-row">
                    <div>清华大学出版社</div>
                    <div className="text-right">《你不知道的美国留学》</div>
                  </h2>
                  <p>
                    免费阅读电子书，搜索常见申请问题，得到权威回答
                    <br className="hidden md:block" />
                    让您的申请路上不再踩坑
                  </p>
                  <div className="mt-10 md:mt-0">
                    <div className="mt-4  font-bold">
                      <span className="text-#18A00F mr-1">Q</span>
                      在美国读研究生要花多少钱？
                    </div>
                    <div className="my-1 flex">
                      <span className="text-#FC9F4A mr-1 font-bold">A</span>
                      在赴美读研的费用上，根据你的专业不同、学校性质不同(公立大学、私立大学)、学校所在城市的消费水平不一样，数据也大不相同。平均来说，研究生一年的学费在40-50万人民币左右，会根据不同学校的收费标准有所变化。很多传媒类、艺术类专业收费更贵。虽然研究生也是有提供奖学金的，但是比例较低，要做好全自费的准备。
                      <img className="md:hidden w-[35vw] object-contain self-end" src="/static/book.png" />
                    </div>
                  </div>
                </div>
              </div>
              <div className=" hidden md:block md-1/3">
                <img className="w-full h-96 object-contain" src="/static/book.png" />
              </div>
            </div>
            <div className="absolute left-0 bottom-0 z-10 h-[350px] w-full">
              <div
                className="h-full w-full bg-center bg-cover"
                style={{ background: 'url(/static/db-background.svg)', transform: 'scaleX(-1)' }}
              />
            </div>
          </div>
        </a>
      </Link>
      <style jsx>{`
        .text-shadow {
          text-shadow: 2px 3px 2px rgba(111, 227, 227, 0.4);
        }
      `}</style>
    </div>
  );
}
