import React from 'react';
import { Rate } from 'antd';
import _ from 'lodash';

import FiverrReviews from '~/data/fiverr_reviews.json';
import { externalLinks } from '~/utils/Constants';

const Fiverr = () => {
  return (
    <div className="flex bg-#F3F5F5 md:pt-16">
      <div
        className=" relative flex-1 flex flex-col max-w-6xl m-auto items-center md:items-end bg-no-repeat bg-contain bg-left-bottom md:bg-left-top pb-28 md:pb-10"
        style={{
          backgroundImage: 'url("/static/IndexPage/fiverr-bg.svg")',
        }}>
        <div className="text-#FC9F4A font-bold flex flex-col leading-none items-end absolute right-7 md:right-auto bottom-3 md:left-6 md:bottom-9">
          <span className="text-[50px]">Fiverr</span>
          <span className="text-xl">性价比最高的文书修改服务</span>
        </div>
        <a href="https://instant.1point3acres.cn/forum/433" target="_blank">
          <div className="md:text-base text-#5E5E5E text-right font-semibold md:w-full mt-10 md:mb-2">
            查看一亩三分地申请人的 <span className=" text-[25px] md:text-3xl text-#21A318 font-bold">真实评价</span>
          </div>
          <div className="md:text-base text-#5E5E5E text-right font-semibold md:w-full md:mb-5">
            选择最适合你的文书编辑，写出最好的留学文书
          </div>
          <a className="text-base" href="https://instant.1point3acres.cn/thread/809379" target="_blank">
            🔥 Offer多多用户专享10% off，优惠码: 10FIVERR1P3A
          </a>
        </a>
        <div className="justify-end grid md:grid-cols-3 gap-3 w-[294px] md:w-3/4 align-middle min-h-[56px] mt-3 text-xs">
          {_.slice(_.shuffle(FiverrReviews), 0, 3).map(({ editor, rating, comment, thread }) => (
            <a
              key={thread}
              className="relative flex flex-col justify-between text-#5E5E5E bg-#5E5E5E bg-opacity-10 p-5 no-underline"
              href={externalLinks.fiverr_editor(editor)}
              target="_blank">
              <div>
                <img src="/static/IndexPage/quotes.svg" className="absolute -left-3 -top-4 w-10 h-10" />
                <div className=" font-bold truncate">
                  <span className="text-primary text-sm mr-1">{editor}</span>
                  <span className=" text-lg">文书修改评价</span>
                </div>
                <div>
                  <span className="text-sm font-semibold align-middle">{Math.round(rating / 2, 1)}/5</span>{' '}
                  <Rate
                    disabled
                    allowHalf
                    defaultValue={Math.round(rating / 2, 1)}
                    className="text-#FFA500 small text-xs"
                  />
                </div>
                <div className="mt-3 relative fade">{comment}</div>
              </div>
            </a>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Fiverr;
