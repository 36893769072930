/* eslint-disable no-useless-escape */
import React from 'react';
import { Form } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { useRouter } from 'next/router';

import useResponsive from '~/hooks/useResponsive';
import OfferItem from '~/components/OfferFormItems';
import TermYearSelect from '~/components/OfferFormItems/TermYearSelect';
import PlanMajorSelect from '~/components/OfferFormItems/PlanMajorSelect';
import SchoolNameSelect from '~/components/OfferFormItems/SchoolNameSelect';

const OptionWrapper = ({ children }) => {
  return (
    <>
      <section>
        <SearchOutlined style={{ color: 'var(--brown-grey)', fontSize: '16px' }} />
        <div>{children}</div>
      </section>
      <style jsx>{`
        section {
          display: flex;
          align-items: center;
          div {
            margin-left: 10px;
            width: 100%;
          }
        }
      `}</style>
    </>
  );
};

const Search = ({ placeholder }) => {
  const router = useRouter();
  const [advancedSearch, setAdvancedSearch] = React.useState(false);
  const { lg } = useResponsive();
  const params = {};

  const handleFinish = values => {
    const searchParams = new URLSearchParams(
      Object.fromEntries(Object.entries({ ...values, ...params }).filter(entry => entry[1] != null))
    );
    router.push(`/results?${searchParams.toString()}`);
  };

  const handleChange = ({ planyr, planterm }) => {
    params.planyr = planyr;
    params.planterm = planterm;
  };

  return (
    <>
      <div className="container index-page-search">
        <Form onFinish={handleFinish} layout="inline">
          <div className="form-container">
            <div className="row">
              <OfferItem name="outname_w" formItemProps={{ colon: false, className: 'flex-1 truncate' }}>
                <SchoolNameSelect
                  placeholder={placeholder || '搜索学校'}
                  listHeight={270}
                  OptionWrapper={OptionWrapper}
                  className="index-search-school-name-select"
                />
              </OfferItem>
              <OfferItem formItemProps={{ colon: false, className: 'term-year' }}>
                <TermYearSelect onChange={handleChange} dropdownMatchSelectWidth={lg} />
              </OfferItem>
            </div>
            {advancedSearch && (
              <div className="row advanced-row">
                <OfferItem
                  name="planmajor"
                  title=""
                  description=""
                  placeholder="请选择录取专业"
                  formItemProps={{ colon: false, className: 'planmajor' }}>
                  <PlanMajorSelect />
                </OfferItem>
                <OfferItem
                  name="plandegree"
                  title=""
                  description=""
                  placeholder="请选择录取学位"
                  formItemProps={{ colon: false, className: 'plandegree' }}
                />
                <OfferItem
                  name="result"
                  title=""
                  description=""
                  placeholder="请选择录取结果"
                  formItemProps={{ colon: false, className: 'result' }}
                />
              </div>
            )}
          </div>
          <button type="submit">搜索</button>
          <a
            className="advanced-search text-#FFF4DF ml-3 self-center"
            href="#"
            onClick={e => {
              e.preventDefault();
              setAdvancedSearch(!advancedSearch);
            }}>
            高级搜索>>
          </a>
        </Form>
      </div>
      <style jsx>{`
        .search-container {
          display: flex;
          justify-content: center;

          a.advanced-search {
            margin: 15px 0px 0px 15px;
            font-size: 13px;
          }
          :global(form) {
            display: flex;
            align-items: flex-start;
            position: relative;
          }
        }
        .row {
          display: flex;
          align-items: center;
          height: 48px;
          background-color: var(--white);

          &:nth-child(2) {
            border-top: solid 0.5px var(--brown-grey-28);
          }
          :global(.ant-form-item) {
            margin: 0px;
          }
          :global(.ant-form-item:last-child .ant-select-selector) {
            border: none !important;
          }
        }
        button[type='submit'] {
          border: none;
          background-color: var(--blue-green);
          box-shadow: 0 0 6px 0 var(--black-17);
          color: var(--white);
          border-radius: 0px 20px 20px 0px;
          width: 63px;
          height: 50px;
          outline: none;
          cursor: pointer;
        }
        .form-container {
          flex: 1;
          border-radius: 20px 0px ${advancedSearch ? '20' : '0'}px 20px;
          box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.08);
          border: solid 0.5px rgba(155, 155, 155, 0.28);
          overflow: hidden;

          :global(.ant-select-arrow),
          :global(.ant-form-item-label) {
            display: none;
          }
          :global(.index-search-school-name-select) {
            display: flex;
          }
          :global(.school-name) {
            width: 388px;
          }
          :global(.plandegree) {
            width: 188px;
          }
          :global(.planmajor) {
            width: 236px;
            :global(.ant-select-selector) {
              padding-left: 40px;
            }
            :global(input) {
              padding-left: 25px !important;
            }
          }
          :global(.result) {
            width: 160px !important;
          }
          :global(.ant-form-item-control-input) {
            text-align: left;
          }
          :global(.ant-select-selector) {
            height: 26px;
            display: flex;
            align-items: center;
            border: none;
            border-right: 1px solid var(--very-light-pink-three) !important;
            border-radius: 0px;
          }
          :global(.ant-select-focused.ant-select-single:not(.ant-select-customize-input) .ant-select-selector) {
            box-shadow: none;
          }
          :global(.anticon-search) {
            color: var(--brown-grey);
            margin: 0px 6px 0px 15px;
          }
          :global(.term-year) {
            width: 108px;
          }
        }
        @media screen and (min-width: 768px) {
          :global(.advanced-search) {
          }
          .search-container {
            height: 90px;
          }
        }
        @media screen and (max-width: 768px) {
          .advanced-search {
            width: 100%;
            margin: 9px 0px 0px 0px;
            text-align: right;
          }
          .advanced-row {
            height: 70px;
          }
          .search-container {
            :global(form) {
              flex-wrap: wrap;
              justify-content: center;
              align-content: flex-start;
            }
          }
          .form-container {
            flex: 1;
            :global(.index-search-school-name-select) {
              min-width: 150px;
            }
            :global(.school-name) {
            }
            :global(.ant-form-item-control-input) {
              min-height: 21px;
              height: 21px;

              input {
                height: 20px;
              }
            }
            :global(.planmajor) {
              :global(.ant-select-selector) {
                padding-left: 11px;
              }
              :global(input) {
                padding-left: 0px !important;
              }
            }
            :global(.term-year) {
              width: 80px;
            }
          }
          .row {
            overflow: hidden;
            width: auto;

            &:nth-child(2) {
              padding-left: 0px;
              flex-direction: column;
              align-items: flex-start;

              :global(.ant-select-selector) {
                border: none !important;
              }
            }
          }
        }
      `}</style>
    </>
  );
};

export default Search;
