import React from 'react';
import Link from 'next/link';
import { Button } from 'antd';

import { User } from '~/services/agent';
import NavItems from '~/components/IndexPage/NavItems';

const VIP_MENU_LEFT = [
  { title: '我的背景', icon: '/static/IndexPage/nav-icon-1.svg', href: '/my/preparations' },
  { title: '智能选校', icon: '/static/IndexPage/nav-icon-3.svg', href: '/my/recommend', vip: true },
];

const VIP_MENU_RIGHT = [
  { title: '学校项目对比', icon: '/static/IndexPage/nav-icon-4.svg', href: '/my/decision' },
  { title: '进程管理', icon: '/static/IndexPage/nav-icon-6.svg', href: '/my' },
];

const renderMenuItem = item => (
  <Link key={item.title} href={item.href}>
    <a className="relative flex flex-col m-auto md:w-[120px] md:h-[88px] items-center justify-center hover:shadow-2">
      <img src={item.icon} className="w-6 h-6 md:w-8 md:h-8" />
      {item.vip && (
        <div className="absolute -right-2 md:right-0 -top-1 md:top-1 text-white bg-#FC9F4A rounded-md px-0.5 text-xxs">
          白金卡
        </div>
      )}
      <span className=" text-primary font-bold text-sm mt-3">{item.title}</span>
    </a>
  </Link>
);

export default function Nav() {
  const { isLogin, isVip } = User.getMyProfile();
  return (
    <div
      className="relative w-full mt-16 bg-left-bottom bg-no-repeat"
      style={{ background: 'url(/static/IndexPage/nav-bg.svg)' }}>
      <div className="w-full m-auto flex-1 rounded-xl">
        <div className="mx-8 md:mx-0">
          <NavItems />
        </div>
        <div className="text-center text-#ABABAB font-bold text-lg mt-16 mb-4">
          {!isVip &&
            (isLogin ? (
              <Link href="/pricing">
                <a>
                  <Button className="mx-6 bg-#18A00F text-white font-bold border-none">升级VIP</Button>
                </a>
              </Link>
            ) : (
              <a href="https://auth.1point3acres.cn/login">
                <Button className="mx-6 bg-#18A00F text-white font-bold border-none">注册/登录</Button>
              </a>
            ))}
          <Link href="/guide">
            <a>
              <Button className="mx-6 bg-#18A00F text-white font-bold border-none">新人导读</Button>
            </a>
          </Link>
        </div>
        <div className=" md:h-[150px] m-auto mt-3 md:mt-10 max-w-6xl rounded-[20px] md:flex items-center py-2 md:bg-gradient-to-r md:from-#FFEED3 md:to-#FFF7EA md:shadow-inset-1">
          <div className="flex-1 grid grid-cols-2 mx-4 py-4 lg:mx-0 lg:py-0 rounded-lg lg:rounded-none bg-gradient-to-r md:bg-none from-#FFEED3 to-#FFF7EA shadow-inset-1 md:shadow-none">
            {VIP_MENU_LEFT.map(renderMenuItem)}
          </div>
          <div className="w-40 md:w-auto mx-auto mt-2 mb-1 md:mt-0 md:mb-0 flex flex-col text-center text-xs">
            <span className="md:text-xl font-semibold">量身定制</span>
            <Link href="/pricing">
              <a className=" text-white bg-#FC9F4A rounded-full py-px px-2">使用白金卡开启全部功能</a>
            </Link>
            <span className="mt-1">大数据驱动+个性化推荐</span>
            <span>全面管理择校申请全过程</span>
          </div>
          <div className="flex-1 grid grid-cols-2 mx-4 py-4 lg:mx-0 lg:py-0 rounded-lg lg:rounded-none bg-gradient-to-r md:bg-none from-#FFEED3 to-#FFF7EA shadow-inset-1 md:shadow-none">
            {VIP_MENU_RIGHT.map(renderMenuItem)}
          </div>
        </div>
      </div>
    </div>
  );
}
