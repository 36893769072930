import React from 'react';

const Background = ({ height = '518px', smHeight = '570px' }) => {
  return (
    <div className="bg">
      <style jsx>{`
        .bg {
          position: absolute;
          height: ${height};
          width: 100%;
          top: 64px;
          left: 0px;
          background: linear-gradient(
            180deg,
            #497b89 0%,
            rgba(79, 137, 142, 0.760417) 39.06%,
            rgba(99, 181, 156, 0) 100%
          );
          display: flex;
          align-items: center;
          div {
            width: 1240px;
            margin: 0px auto;
          }
          img {
            width: 511px;
            height: 275px;
          }
        }
        @media screen and (max-width: 576px) {
          .bg {
            height: ${smHeight};

            img {
              display: none;
            }
          }
        }
      `}</style>
    </div>
  );
};

export default Background;
