import React from 'react';
import Link from 'next/link';

import Admission from '~/components/IndexPage/Admission';
import Discuss from '~/components/IndexPage/Discuss';
import LineChart from '~/components/university/LineChart';

const Feed = ({ latestResults, latestAdmissionThreads, demoAdmissionStats }) => (
  <div className="bg-#F3F5F5 pt-[72px]">
    <div className="md:flex md:max-w-6xl m-auto h-full">
      <div className="md:w-1/2 md:px-5 flex flex-col">
        <div className="mx-[30px] md:mx-4 flex-1 flex flex-col justify-center items-center">
          <div>
            <strong className="text-3xl md:text-[40px] tracking-[6px]">实时申请信息</strong>
            <p className="mt-3 tracking-wider">
              最大的留学社区，最全的申请数据。最新的录取信息。
              <br />
              与小伙伴们互帮互助，一起进入梦校，申请路上再无彷徨。
            </p>
          </div>
        </div>
        <div className="mt-8">
          <Discuss threads={latestAdmissionThreads} fid={27} />
        </div>
      </div>
      <div className="md:w-1/2 my-[30px] md:my-0">
        <Admission latestResults={latestResults} />
        <div className="mt-[30px] md:mt-5 px-5 md:px-6 pt-1 pb-2 bg-white shadow-md md:rounded-md">
          <LineChart
            predefinedStats={demoAdmissionStats}
            predefinedTitle={
              <div className="w-full flex justify-between mb-1">
                <span className="text-#5E5E5E font-bold">往届放榜时间线</span>
                <Link href="/db/colleges">
                  <a>查看各学校放榜时间线</a>
                </Link>
              </div>
            }
          />
        </div>
      </div>
    </div>
  </div>
);

export default Feed;
