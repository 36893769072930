import React from 'react';
import { Form, Select } from 'antd';
import ReactEcharts from 'echarts-for-react';
import moment from 'moment';

import { Colleges } from '~/services/agent';
import useUniversity from '~/hooks/useUniversity';
import { toFilters } from '~/utils/University';

export default function LineChart({ predefinedStats, predefinedTitle }) {
  const [filters, setFilters] = React.useState({});
  let data;
  if (predefinedStats) {
    data = predefinedStats;
  } else {
    const contextData = useUniversity();
    const res = Colleges.trends(
      toFilters(contextData, { college: 'outname_w', major: 'planmajor' }, true),
      `&${new URLSearchParams(filters).toString()}`
    );
    data = res?.data?.stats || [];
  }
  const currentYear = new Date().getFullYear();
  const currentWeek = moment().format('WW');
  const colors = ['#CC4242', '#18A10F', '#FC9F4A'];
  const years = Array.from({ length: 3 }).map((v, i) => {
    const j = filters['planterm'] === '2' ? 1 : 0;
    return `${currentYear - i + j}`;
  });
  function handleChange(changedValues, allValues) {
    setFilters({ ...filters, ...allValues });
  }

  const option = {
    tooltip: {
      trigger: 'axis',
      backgroundColor: 'rgba(94, 94, 94, 0.8)',
      borderWidth: 2,
      borderColor: '#DCDCDC',
      textStyle: {
        color: '#fff',
      },
      formatter(params) {
        const value = params[0].name;
        const week = value <= currentWeek ? `${currentYear}-${value}` : `${currentYear - 1}-${value}`;
        const time = moment(week, 'YYYY-WW').format('YYYY-MM-DD');
        return `
          <div class="font-bold">
            第${params[0].name}周（起始于 ${time}）
          </div>
          ${params
            .map(v => {
              const fallNum = data[v.dataIndex][v.seriesName]?.['Fall'] || 0;
              const springNum = data[v.dataIndex][v.seriesName]?.['Spring'] || 0;
              const springText = springNum > 0 ? `Spring: ${springNum}` : '';
              const fallText = fallNum > 0 ? `Fall: ${fallNum}` : '';
              return `<div>${v.marker} ${v.seriesName}-${fallText} ${springText}</div>`;
            })
            .join('')}
          `;
      },
    },
    legend: {
      right: 0,
      itemWidth: 15,
      itemHeight: 2,
      itemStyle: {
        opacity: 0,
      },
      textStyle: {
        fontSize: 8,
        color: '#5E5E5E',
      },
      data: years,
    },
    grid: {
      left: 10,
      right: 15,
      bottom: 0,
      top: 25,
      containLabel: true,
    },
    xAxis: {
      name: '周',
      nameLocation: 'end',
      nameGap: 3,
      nameTextStyle: { verticalAlign: 'top', padding: [8, 0, 0, 0] },
      type: 'category',
      splitLine: {
        show: true,
        lineStyle: {
          color: '#DCDCDC',
          type: 'dashed',
        },
      },
      axisLine: {
        show: false,
      },
      axisTick: {
        show: false,
      },
      data: data.map(v => v.name),
    },
    yAxis: {
      name: '数量',
      nameLocation: 'end',
      nameGap: 9,
      nameTextStyle: { align: 'right' },
      type: 'value',
      splitLine: {
        show: true,
        lineStyle: {
          color: '#DCDCDC',
          type: 'dashed',
        },
      },
    },
    series: years.map((year, i) => ({
      type: 'line',
      name: year,
      color: colors[i],
      smooth: true,
      data: data.map(v => v[year]?.['Fall'] || 0 + v[year]?.['Spring'] || 0),
    })),
  };
  return (
    <div className="mt-5" id="timeline">
      <div className="flex justify-between items-center text-#5E5E5E border-b border-#DCDCDC">
        {predefinedTitle || <div className="text-#5E5E5E font-bold">放榜时间线</div>}
        {!predefinedStats && (
          <div>
            <Form layout="inline" onValuesChange={handleChange}>
              <Form.Item name="planterm" label="">
                <Select placeholder="学期" size="small" style={{ width: 85 }}>
                  <Select.Option value="1">Fall</Select.Option>
                  <Select.Option value="2">Spring</Select.Option>
                </Select>
              </Form.Item>
              <Form.Item name="result" label="" className="mr-0">
                <Select placeholder="申请结果" size="small" style={{ width: 85 }}>
                  <Select.Option value="1">Offer全奖</Select.Option>
                  <Select.Option value="2">AD小奖</Select.Option>
                  <Select.Option value="3">AD无奖</Select.Option>
                  <Select.Option value="4">Reject</Select.Option>
                  <Select.Option value="5">Waiting</Select.Option>
                </Select>
              </Form.Item>
            </Form>
          </div>
        )}
      </div>
      <ReactEcharts option={option} className="h-[268px]" />
    </div>
  );
}
