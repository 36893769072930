import React from 'react';
import Link from 'next/link';
import moment from 'moment';
import _ from 'lodash';

import { toShortNoticemethod } from '~/utils/mapping';

const Admission = ({ latestResults }) => {
  return (
    <div className="flex flex-col space-y-1 text-#5E5E5E bg-white px-5 md:px-8 py-4 shadow-md md:rounded-md">
      <div className="w-full flex justify-between align-center">
        <strong className="text-#5E5E5E font-semibold">最新录取汇报</strong>{' '}
        <Link href="/report">
          <a>
            <div className="px-3 py-2 text-sm text-#FC9F4A font-semibold bg-#FFF4DF rounded">汇报申请结果</div>
          </a>
        </Link>
      </div>
      {_.map(latestResults, r => (
        <div className="text-#5E5E5E border-b border-#F3F5F5 pb-3">
          <div>
            <span className={`mr-3 font-semibold ${r.result === 'Reject' ? `text-#CB4141` : 'text-#18A00F'}`}>
              {toShortNoticemethod(r.noticemethod)}/{r.result}
            </span>{' '}
            {moment.unix(r.dateline).fromNow()}
          </div>
          <div className="text-primary">
            <Link href={`${r.program_info.program_url}`}>
              <a>{r.program_info.program_full_name}</a>
            </Link>
          </div>
          <div>
            <Link href={`/university/${r.outname_w.toLowerCase()}`}>
              <a className="text-current">
                <span className="text-#5E5E5E font-bold">
                  {r.program_info.school_name} ({r.program_info.short_name})
                </span>
                <span className="ml-3">{r.program_info.school_name_cn}</span>
              </a>
            </Link>
          </div>
        </div>
      ))}
      <Link href="/results">
        <a className="text-sm">查看全部汇报>></a>
      </Link>
    </div>
  );
};

export default Admission;
