import React from 'react';
import Link from 'next/link';
import { Carousel } from 'antd';
import _ from 'lodash';

import OrangeButton from '~/components/OrangeButton';
import { getItemValue } from '~/components/FormItems/Utils';

function PrevArrow({ className, onClick }) {
  return (
    <div className={`${className} w-10 h-10 left-[-40px] top-5`} onClick={onClick}>
      <img src="/static/IndexPage/carousel-arrow.svg" />
    </div>
  );
}

function NextArrow({ className, onClick }) {
  return (
    <div className={`${className} w-10 h-10 right-[-40px] top-5`} onClick={onClick}>
      <img src="/static/IndexPage/carousel-arrow.svg" className=" transform rotate-180" />
    </div>
  );
}

function Item({ college }) {
  return (
    <div className="flex min-w-[148px] h-[70px] bg-white rounded p-[10px] items-center">
      <img src={college.logo_image} className="w-[50px] h-[50px] mr-[10px]" />
      <div>
        <strong>{college.school_name}</strong>
        <div className="flex space-x-2">
          {college.years.slice(-2).map(v => {
            const year = getItemValue('planyr', v);
            return (
              <Link key={v} href={`/db/cn-colleges/${college.school_name}/${year}`}>
                <a className=" font-bold text-xs">{year}</a>
              </Link>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default function CnColleges({ data }) {
  return (
    <div className="h-[259px] bg-#BDCCCF ">
      <div
        className="max-w-6xl mx-auto py-[30px] bg-no-repeat h-full"
        style={{ background: 'url(/static/IndexPage/map.png)' }}>
        <div className="font-bold text-[25px] text-white text-center">国内院校出国报告</div>
        <div className="my-[30px] mx-10 hidden md:block">
          <Carousel dots={false} arrows prevArrow={<PrevArrow />} nextArrow={<NextArrow />}>
            {_.chunk(data, 6).map((chunk, index) => (
              <div key={index} className="flex space-x-5 justify-center">
                {chunk.map(v => (
                  <Item key={v.school_name} college={v} />
                ))}
              </div>
            ))}
          </Carousel>
        </div>
        <div className="my-[30px] md:hidden flex space-x-5 overflow-auto pl-[30px]">
          {data.map(v => (
            <Item key={v.school_name} college={v} />
          ))}
        </div>
        <div className="text-center">
          <Link href="/db/cn-colleges">
            <a>
              <OrangeButton>全部出国报告</OrangeButton>
            </a>
          </Link>
        </div>
      </div>
    </div>
  );
}
