import React from 'react';
import Link from 'next/link';
import { Tabs } from 'antd';
import { MessageOutlined, LikeOutlined } from '@ant-design/icons';

import { bbsEndpoint } from '~/utils/Constants';
import BasicInfo from '~/components/university/BasicInfo';
import UniversityContext from '~/contexts/UniversityContext';

const School = ({ college, program, major, latestIntroThreads }) => {
  if (!college) {
    return null;
  }
  return (
    <div className="bg-#F3F5F5">
      <div className="max-w-6xl m-auto py-10">
        <div className="w-full md:flex md:gap-12 px-5 md:px-12 mb-8 md:mb-4">
          <div className="md:w-1/4">
            <Link href="/my/decision">
              <a>
                <img
                  className=" hidden md:block m-auto max-w-[300px] object-contain"
                  src="/static/IndexPage/school-demo.svg"
                />
                <img className="w-full mb-8 md:hidden" src="/static/IndexPage/school-demo-m.svg" />
              </a>
            </Link>
          </div>
          <div className="flex-1 flex flex-col space-y-3">
            {latestIntroThreads.map(v => (
              <a key={v.tid} href={bbsEndpoint.THREAD(v.tid)} target="_blank" className="text-current">
                <div className="flex text-sm justify-between">
                  <div className="font-bold truncate">{v.subject}</div>
                  <div className="flex items-center ml-2 text-#ABABAB">
                    <MessageOutlined className="mr-1" /> {v.replies}
                    <div className="bg-#ABABAB  w-[1px] mx-3 h-2/3 py-1" />
                    <LikeOutlined className="mr-1" /> {v.heats}
                  </div>
                </div>
                <div className="mt-2 line-clamp-3 text-xs relative fade">{v.summary}</div>
              </a>
            ))}
            <a href="https://instant.1point3acres.cn/forum/71" target="_blank" className="text-right">
              全部院系点评>>
            </a>
          </div>
        </div>
        <Tabs tabPosition="bottom" type="card" className="button-tab">
          <Tabs.TabPane tab="学校" key="1">
            <UniversityContext.Provider value={{ type: 'college', college }}>
              <BasicInfo readOnly />
            </UniversityContext.Provider>
          </Tabs.TabPane>
          <Tabs.TabPane tab="项目" key="2">
            <UniversityContext.Provider value={{ type: 'program', college: program.college_info, program }}>
              <BasicInfo readOnly />
            </UniversityContext.Provider>
          </Tabs.TabPane>
          <Tabs.TabPane tab="专业" key="3">
            <UniversityContext.Provider value={{ type: 'major', college: major.college, major: major.major }}>
              <BasicInfo readOnly />
            </UniversityContext.Provider>
          </Tabs.TabPane>
        </Tabs>
      </div>
    </div>
  );
};

export default School;
