import React from 'react';

import { Select } from '~/components/antd';
import { getItemChoices, getItemKey, getItemValue } from '~/components/FormItems/Utils';

const { Option } = Select;

const TermYearSelect = ({ onChange, defaultValue, value, sort, ...rest }) => {
  const currentYear = new Date().getFullYear();

  const years = sort
    ? Array.from({ length: 3 }).map((_, i) => currentYear + i)
    : Array.from({ length: 3 })
        .map((_, i) => currentYear + (1 - i))
        .reverse();

  const otherYears = Object.values(getItemChoices('planyr'))
    .map(Number)
    .filter(v => !years.includes(v))
    .sort();

  const plantermValues = Object.values(getItemChoices('planterm'));
  const options = years.concat(otherYears).reduce((r, v) => {
    r.push(...Object.values(plantermValues).map(term => `${v}-${term}`));
    return r;
  }, []);

  const handleChange = v => {
    const values = {};
    if (v) {
      const [planyr, planterm] = v.split('-');
      values.value = v;
      values.planyr = getItemKey('planyr', planyr);
      values.planterm = getItemKey('planterm', planterm);
    }
    if (onChange) {
      onChange(values);
    }
  };

  React.useEffect(() => {
    if (typeof value === 'string') {
      handleChange(value);
    }
  }, [value]);

  const _value = (value instanceof Object ? value?.value : value) || defaultValue;

  return (
    <Select name="year" placeholder="学年" onChange={handleChange} value={_value} {...rest}>
      {options.map(option => (
        <Option key={option}>{option}</Option>
      ))}
    </Select>
  );
};

TermYearSelect.value = ({ planyr, planterm }) => {
  if (planyr && planterm) {
    return `${getItemValue('planyr', planyr)}-${getItemValue('planterm', planterm)}`;
  }
  return null;
};

export default TermYearSelect;
