import React from 'react';
import Link from 'next/link';

import Search from './Search';
import ReleasedUniversities from '~/components/my/ReleasedUniversity';

const TopBanner = ({ trendingColleges, trendingPrograms }) => {
  return (
    <section className=" relative text-#5E5E5E">
      <div className="flex max-w-6xl mx-auto pt-5 lg:pt-20">
        <img
          src="/static/IndexPage/laptop.png"
          className=" w-[419px] h-[404px] hidden lg:block -ml-16 xl:-ml-32 mr-5"
        />
        <div className="flex-1 max-w-full">
          <div className="px-7 lg:px-0">
            <div className="max-w-[520px] overflow-hidden">
              <ReleasedUniversities rejColor="#F9D38D" offerColor="#8FDD7E" linkOffer />
            </div>
            <Search placeholder="搜索您的梦校：飞跃重洋，追寻梦想" />
          </div>
          <div className="overflow-auto px-7 lg:px-0 flex mt-10 lg:mt-[70px] lg:justify-center space-x-5 md:space-x-6 pb-5">
            <div className="bg-white rounded flex-shrink-0 w-[270px] h-[245px] md:h-auto py-5 px-[30px] md:px-5 shadow-3">
              <div className="ml-2 md:ml-0">
                <strong className=" text-base text-#F58E30 font-bold mr-2">热门大学</strong>
                <Link href="/db/colleges">
                  <a className="text-primary text-xs">更多</a>
                </Link>
              </div>
              <ul className="text-sm flex flex-col space-y-4 md:space-y-1 mt-3 md:mt-2">
                {trendingColleges.map(item => (
                  <li key={item.short_name}>
                    <Link href={`/university/${item.short_name.toLowerCase()}`}>
                      <a className="text-#5E5E5E flex items-center">
                        <span className="w-1 h-1 bg-#F58E30 rounded-full mr-1" />
                        <div className="truncate">{item.full_name}</div>
                      </a>
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
            <div className="bg-white rounded flex-shrink-0 w-[270px] h-[245px] md:h-auto py-5 px-[30px] md:px-5 shadow-3">
              <div className="ml-2 md:ml-0">
                <strong className="text-base text-#F58E30 font-bold mr-2">热门项目</strong>{' '}
                <Link href="/db/programs">
                  <a className="text-primary text-xs">更多</a>
                </Link>
              </div>
              <ul className="text-sm flex flex-col space-y-4 md:space-y-1 mt-3 md:mt-2">
                {trendingPrograms.map(item => (
                  <li key={item.seo_url}>
                    <Link href={`/program/${item.seo_url}`}>
                      <a className="text-#5E5E5E flex items-center">
                        <span className="w-1 h-1 bg-#F58E30 rounded-full mr-1" />
                        <div className="truncate">
                          {item.short_name} - {item.degree} - {item.program_name}
                        </div>
                      </a>
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TopBanner;
