import React from 'react';
import _ from 'lodash';

import Layout from '~/components/Layout';
import Background from '~/components/IndexPage/Background';
import TopBanner from '~/components/IndexPage/TopBanner';
import Nav from '~/components/IndexPage/Nav';
import Feed from '~/components/IndexPage/Feed';
import Fiverr from '~/components/IndexPage/Fiverr';
import Experience from '~/components/IndexPage/Experience';
import Competencies from '~/components/IndexPage/Competencies';
import CollegeDemo from '~/components/IndexPage/CollegeDemo';
import AdmissionAds from '~/components/IndexPage/AdmissionAds';
import FAQBanner from '~/components/db/FAQBanner';
import School from '~/components/IndexPage/School';
import Travel from '~/components/IndexPage/Travel';
import CnColleges from '~/components/IndexPage/CnColleges';
import { Colleges, Discuz, Programs, Results, Stats, Majors } from '~/services/agent';

const Index = ({
  trendingColleges,
  trendingPrograms,
  latestResults,
  latestAdmissionThreads,
  latestDecisionThreads,
  latestTravelThreads,
  latestF1VisaThreads,
  latestIntroThreads,
  latestAdmissionAdThreads,
  latestExperienceThreads,
  demoAdmissionStats,
  competencyData,
  demoCollege,
  program,
  major,
  cnCooleges,
}) => {
  return (
    <Layout noContainer>
      <Background />
      <TopBanner trendingColleges={trendingColleges} trendingPrograms={trendingPrograms} />
      <Nav />
      <Feed
        latestResults={latestResults}
        latestAdmissionThreads={latestAdmissionThreads}
        demoAdmissionStats={demoAdmissionStats}
      />
      <Fiverr />
      <Experience threads={latestExperienceThreads} />
      <Competencies competencyData={competencyData} />
      <CollegeDemo college={demoCollege} program={program} major={major} latestIntroThreads={latestIntroThreads} />
      <FAQBanner />
      <AdmissionAds threads={latestAdmissionAdThreads} />
      <School latestDecisionThreads={latestDecisionThreads} trendingColleges={trendingColleges} />
      <CnColleges data={cnCooleges} />
      <Travel latestTravelThreads={latestTravelThreads} latestF1VisaThreads={latestF1VisaThreads} />
      <style jsx>{`
        main {
          display: flex;
          justify-content: space-between;
          margin-top: 36px;

          .left {
            width: 572px;
          }
          .right {
            width: 450px;
            display: flex;
            flex-direction: column;
          }
        }
        .tab {
          :global(.ant-tabs-nav-list) {
            margin-left: 15px;
          }
          :global(.ant-tabs-nav) {
            margin: 0px;
          }
        }
      `}</style>
    </Layout>
  );
};

export async function getStaticProps() {
  const {
    res: { colleges: trendingColleges },
  } = await Colleges.getTrendingColleges();
  const {
    res: { programs: trendingPrograms },
  } = await Programs.getTrendingPrograms();

  const demoProgramMapping = {
    cmu: 127,
    columbia: 155,
    usc: 823,
    neu: 353,
    jhu: 288,
  };
  const demoCollegeName = _.sample(_.keys(demoProgramMapping));

  const [
    {
      res: { results: latestResults },
    },
    {
      res: { threads: latestAdmissionThreads },
    },
    {
      res: { threads: latestDecisionThreads },
    },
    {
      res: { threads: latestTravelThreads },
    },
    {
      res: { threads: latestF1VisaThreads },
    },
    {
      res: { threads: latestIntroThreads },
    },
    {
      res: { threads: latestAdmissionAdThreads },
    },
    {
      res: { threads: latestExperienceThreads },
    },
    {
      res: { stats: demoAdmissionStats },
    },
    {
      res: { data: competencyData },
    },
    {
      res: { data: demoCollege },
    },
    {
      res: { data: program },
    },
    { res: major },
    {
      res: { data: cnCooleges },
    },
  ] = await Promise.all([
    Results.list({}, 4, 1),
    Discuz.getV1ForumThreads({ fid: 27, order: 'time_desc', pg: 1, ps: 5 }),
    Discuz.getForumTypeThreads(144, 165, {}, 'time_desc', 1, 4),
    Discuz.getForumTypeThreads(424, 340, { group_type: 1 }, 'time_desc', 1, 5),
    Discuz.getForumTypeThreads(440, 306, {}, 'time_desc', 1, 7, 'summary,options'),
    Discuz.getV1ForumThreads({ fid: 71, pg: 1, ps: 4 }),
    Discuz.getAdmissionAdThreads(),
    Discuz.getForumTypeThreads(27, 189, {}, 'hot_desc', 1, 12, 'summary,options'),
    Colleges.trends('', ''),
    Stats.getCompetition(),
    Colleges.get(demoCollegeName),
    Programs.get(demoProgramMapping[demoCollegeName]),
    Majors.get(1, demoCollegeName),
    Colleges.getCnColleges(),
  ]);

  const props = {
    trendingColleges: _.sampleSize(trendingColleges, 5),
    trendingPrograms: _.sampleSize(trendingPrograms, 5),
    latestResults,
    latestAdmissionThreads,
    latestDecisionThreads,
    latestTravelThreads,
    latestF1VisaThreads,
    latestIntroThreads,
    latestAdmissionAdThreads,
    latestExperienceThreads,
    demoAdmissionStats,
    competencyData,
    demoCollege,
    program,
    major,
    cnCooleges: cnCooleges.slice(0, 18),
  };

  return {
    props: _.omitBy(props, v => v === undefined),
    revalidate: 3600,
  };
}

export default Index;
