import React from 'react';
import Link from 'next/link';
import Discuss from '~/components/IndexPage/Discuss';
import { bbsEndpoint } from '~/utils/Constants';

const Header = () => (
  <Link href="/my/travel">
    <a className="text-#5E5E5E">
      <div className="w-full md:text-center mb-5 pl-6">
        <strong className="text-3xl md:text-[35px] tracking-[6px] text-primary font-bold">飞跃重洋</strong>
        <p className="mt-3 tracking-wider">收拾行囊，整装待发，拉群结伴，共赴征途</p>
      </div>
    </a>
  </Link>
);

const visaTypeMap = {
  1249: { name: 'F1首签', styles: { color: '#F8B634', borderColor: '#F8B634', backgroundColor: '#FFFBE6' } },
  1250: { name: 'F1返签', styles: { color: '#F8B634', borderColor: '#F8B634', backgroundColor: '#DCF6EE' } },
  default: { name: '其他', styles: { color: '#999', borderColor: '#999', backgroundColor: '#F6F6F6' } },
};

const Travel = ({ latestTravelThreads, latestF1VisaThreads }) => {
  return (
    <div className="bg-#FAFAFA">
      <div className="max-w-6xl mx-auto py-10">
        <Header />
        <div className="grid grid-cols-1 md:grid-cols-2 mt-14 md:gap-5">
          <Discuss threads={latestTravelThreads} fid={424} />

          <div className="flex flex-col justify-between md:mr-2">
            <div className="bg-#FFF8EB rounded-lg flex items-center mt-[30px] md:mt-0 shadow-4">
              <Link href="/my/travel">
                <div className="w-[54px] md:w-20 p-6 text-#FC9F4A text-sm font-semibold bg-#FFF8EB cursor-pointer">
                  签证手续交流
                </div>
              </Link>
              <div className="bg-white py-3 w-[calc(100%-88px)] px-2 md:px-6">
                {latestF1VisaThreads &&
                  latestF1VisaThreads.map(({ tid, subject, typeid }) => (
                    <div
                      key={tid}
                      className="my-1 text-#5E5E5E hover:text-primary border-b border-#DCDCDC border-dashed h-10 flex items-end py-2">
                      <span
                        className="border px-1 flex-shrink-0"
                        style={visaTypeMap?.[typeid]?.styles || visaTypeMap.default.styles}>
                        {visaTypeMap?.[typeid]?.name || visaTypeMap.default.name}
                      </span>
                      <a href={bbsEndpoint.THREAD(tid)} target="_blank" className="ml-1 truncate text-current">
                        {subject}
                      </a>
                    </div>
                  ))}
              </div>
              <div className="w-[30px] text-#FC9F4A text-center text-2xl flex">
                <div className="m-auto">▸</div>
              </div>
            </div>
            <div className=" bg-#DFEDF1 rounded-lg flex items-center h-[104px] mt-[30px] md:mt-0  shadow-4">
              <div className="w-[54px] md:w-20 p-6 text-primary text-sm font-semibold ">关注我们</div>
              <div className="flex-1 flex justify-evenly bg-white h-full items-center text-xs">
                <div className="text-center">
                  <img
                    src="https://oss.1p3a.com/static/image/common/wq_warald.jpg"
                    alt="微信公众号"
                    width="50"
                    height="50"
                    className="m-auto"
                  />
                  <figcaption>微信公众号</figcaption>
                </div>
                <div className="text-center">
                  <img
                    src="https://oss.1p3a.com/static/image/common/wq_liuxue.jpg!p"
                    alt="华人生活号"
                    width="50"
                    height="50"
                    className="m-auto"
                  />
                  <figcaption>华人生活号</figcaption>
                </div>
                <div className="text-center">
                  <img
                    src="https://oss.1p3a.com/static/image/common/wq_invest.jpg"
                    alt="投资理财号"
                    width="50"
                    height="50"
                    className="m-auto"
                  />
                  <figcaption>海外投资号</figcaption>
                </div>
                <div className="text-center">
                  <img
                    src="https://oss.1p3a.com/static/image/common/wq_career.jpg"
                    alt="求职移民号"
                    width="50"
                    height="50"
                    className="m-auto"
                  />
                  <figcaption>求职移民号</figcaption>
                </div>
              </div>
              <div className="w-[30px] text-primary text-center text-2xl flex">
                <div className="m-auto">▸</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Travel;
