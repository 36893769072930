import React from 'react';
import { Empty } from 'antd';

import Time from '~/components/common/Time';
import { bbsEndpoint } from '~/utils/Constants';
import OrangeButton from '~/components/OrangeButton';

const Header = ({ fid }) => (
  <div className="w-full flex justify-between items-center pb-2 border-b border-#F4F4F4">
    <strong className="text-#5E5E5E text-base  font-bold">最新/最热讨论帖</strong>{' '}
    <a href={bbsEndpoint.POST_THREAD(fid)} target="_blank">
      <OrangeButton className="w-[115px]">发起讨论</OrangeButton>
    </a>
  </div>
);

const Discuss = ({ threads, fid }) => {
  return (
    <div className="flex flex-col space-y-1 text-#5E5E5E bg-white px-5 py-5 md:py-6 shadow-md md:rounded-md">
      <Header fid={fid} />
      {threads?.length === 0 && <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
      <div className="flex flex-col divide-y divide-#F4F4F4">
        {threads?.map(v => (
          <a key={v.tid} href={bbsEndpoint.THREAD(v.tid)} target="_blank" className=" relative text-current py-2">
            <div className="flex text-xs truncate ">
              <div className="font-bold mr-4 truncate">{v.subject}</div>
              <div className="text-#ABABAB flex-shrink-0">
                <Time fromNow timestamp={v.lastpost} className="mr-1" />
                {v.lastposter}
              </div>
            </div>
            <div className="mt-1 line-clamp-3 text-xs fade">{v.summary}</div>
          </a>
        ))}
      </div>
      <a href={bbsEndpoint.FORUM(fid)} className="text-sm pt-1" target="_blank">
        查看全部讨论>>
      </a>

      <style jsx>{`
        section {
          background-color: var(--white-two);
          margin-top: 3px;
          padding: 13px;

          &:hover {
            background-color: var(--pale-grey);
          }
          &:first-child {
            margin-top: 0px;
          }
        }
        .title {
          font-weight: bold;
          color: var(--greyish-brown-two);
          display: flex;
          justify-content: space-between;

          span {
            white-space: nowrap;
            overflow: hidden;
            margin-right: 10px;
            text-overflow: ellipsis;
          }

          :global(a) {
            font-size: 12px;
            text-transform: uppercase;
          }
        }
        .post-info {
          font-size: 12px;
          color: var(--blue-green);
          margin-bottom: 4px;
        }
        .summary {
          font-size: 12px;
          color: var(--brownish-grey);
          height: 30px;
          line-height: 15px;
          margin-bottom: 15px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }
        .icons {
          font-size: 12px;
          color: var(--greyish-brown);
          span {
            margin-left: 7px;
            margin-right: 10px;
          }
        }
      `}</style>
    </div>
  );
};

export default Discuss;
