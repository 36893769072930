import React from 'react';
import Link from 'next/link';
import { Input, Select, Button } from 'antd';

import BarChart from '~/components/my/BarChart';
import { toChartData } from '~/utils/University';

const Header = () => (
  <Link href="/my/targets">
    <a className="text-#5E5E5E">
      <div className="w-full md:text-center mb-5">
        <strong className="text-3xl tracking-[6px] text-primary font-bold">我的申请竞争力</strong>
        <p className="text-xs md:text-sm my-3 tracking-wider">知己知彼，方能百战不殆。多维度评估综合竞争实力</p>
        <div className="w-full flex flex-wrap md:justify-center items-center gap-x-2 mt-2 text-#ABABAB">
          <span className="order-5 md:order-1  mt-2 md:mt-0">输入三维成绩查看我的竞争力</span>
          <span className="text-#5E5E5E md:text-#ABABAB order-2">
            GPA <Input className="w-24 mt-0.5" type="text" placeholder="请填写" />
          </span>
          <span className="text-#5E5E5E md:text-#ABABAB order-2">
            TOEFL <Input className="w-24 mt-0.5" type="text" placeholder="请填写" />
          </span>
          <span className="text-#5E5E5E md:text-#ABABAB order-2 mt-2 md:mt-0">
            GRE <Input className="w-24 mt-0.5" type="text" placeholder="请填写" />
          </span>
          <span className="text-#5E5E5E md:text-#ABABAB order-2 mt-2 md:mt-0">
            本科院校水平 <Select className="w-24 mt-0.5" placeholder="请选择" />
          </span>
          <Button className="text-white font-semibold bg-#18A00F border-none order-6 mt-2 md:mt-0">一键查询</Button>
        </div>
      </div>
    </a>
  </Link>
);

const items = [['GPA中位值', 'GPA'], ['GRE中位值', 'GRE'], ['TOEFL中位值', 'TOEFL'], ['本科院校水平', '本科学校']];

const Competencies = ({ competencyData }) => {
  if (competencyData) {
    return (
      <div className="bg-#FAFAFA">
        <div className="max-w-5xl m-auto py-10 ml-6 md:ml-auto">
          <Header />
          <div className="overflow-auto lg:overflow-visible grid grid-cols-[repeat(4,200px)] lg:grid-cols-2 gap-3 lg:gap-x-14 gap-y-7 mt-2">
            {items.map(([title, k]) => (
              <BarChart
                key={title}
                value={competencyData.stats?.[k]}
                title={title}
                data={toChartData(competencyData[k])}
              />
            ))}
          </div>
        </div>
      </div>
    );
  }
  return null;
};

export default Competencies;
