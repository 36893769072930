import React from 'react';

import UniversityContext from '~/contexts/UniversityContext';

export default function useUniversity(props) {
  const value = React.useContext(UniversityContext) || props;

  let id = '';
  let name = '';
  let pathname = '';
  switch (value?.type) {
    case 'college':
      id = value.college.short_name.toLowerCase();
      name = value.college.school_name;
      pathname = 'university';
      break;
    case 'program':
      id = value.program.id;
      name = value.program.program_full_name;
      pathname = 'program';
      break;
    case 'college-school':
      id = value.collegeSchool.id;
      name = value.collegeSchool.name;
      pathname = 'grad-schools-depts';
      break;
    case 'major':
      id = value.major.major_id;
      if (value.college) {
        pathname = `university/${value.college.short_name.toLowerCase()}/majors`;
        name = value.major.name;
      } else {
        pathname = 'majors';
        name = value.major.full_name;
      }
      break;
    default:
      break;
  }

  return {
    ...value,
    id,
    pathname,
    name,
  };
}
