export function toShortNoticemethod(value) {
  return (
    {
      email通知: '邮件',
      书面邮件通知: '书面',
      网申status页面: '网申',
      电话问出来的: '电话',
      others: '其他',
    }[value] || value
  );
}

export function toShortMajorName(value) {
  return (
    {
      'Math/AppliedMath': 'Math',
      'MFE/Fin/FinMath': 'MFE/FinMath',
      'DataScience/Analytics': 'DataScience',
      'HCI/Design/Media': 'HCI/Design',
    }[value] || value
  );
}
export function toCountryEmoji(country) {
  return {
    '1': '🇺🇸',
    '2': '🇨🇦',
    '3': '🇬🇧',
    '4': '🇸🇬',
    '5': '🇯🇵',
    '6': '港澳台',
    '7': '🇦🇺',
    '8': '其他',
  }[country];
}

export function toUserName(name) {
  if (name === '地里的匿名用户') {
    return '匿名用户';
  }
  return name;
}

export const gpa = [['3.5,4.0', '3.5-4'], ['3.0,3.4', '3.0-3.4'], ['0.1,3.0', '<3.0'], ['0,0', 'N/A']];
export const gre = {
  '305,310': '305-310',
  '310,315': '310-315',
  '315,320': '315-320',
  '320,330': '320-330',
  '330,340': '330-340',
};
export const toefl = {
  '110,120': '110-120',
  '98,100': '98-110',
  '90,98': '90-98',
  '80,90': '80-90',
  '70,80': '70-80',
};

export const chartUndertier = {
  '1': '海外',
  '2': '清北科',
  '3': '南大/浙大/复旦/上交',
  '4': 'Top15',
  '5': 'Top30',
  '6': '其他985/211',
  '7': '双非',
};
