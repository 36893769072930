import React from 'react';
import Link from 'next/link';
import _ from 'lodash';
import Discuss from '~/components/IndexPage/Discuss';

const Header = () => (
  <Link href="/my/decision">
    <a className="text-#5E5E5E">
      <div className="px-6 w-full md:text-center mb-5 md:mb-12">
        <strong className="text-3xl tracking-[6px] font-bold">全面比较，理智择校</strong>
        <p className="mt-3 tracking-wider">
          手握多个OFFER，不知如何选择？
          <br />
          这里有最全面的学校比较和项目评价，还有小伙伴们的热烈讨论，快来加入
        </p>
      </div>
    </a>
  </Link>
);

const bestFood = [
  { shortName: 'UCLA', fullName: 'University of California - Los Angeles' },
  { shortName: 'VIRGINIATECH', fullName: 'Virginia Tech' },
  { shortName: 'UMASS', fullName: 'University of Massachusetts - Amherst' },
  { shortName: 'BATES', fullName: 'Bates College' },
  { shortName: 'WUSTL', fullName: 'Washington University in St. Louis' },
];

const bestSafety = [
  { shortName: 'OAKLAND', fullName: 'Oakland University' },
  { shortName: 'PRINCETON', fullName: 'Princeton University' },
  { shortName: 'UNH', fullName: 'University of New Hampshire' },
  { shortName: 'UMASS', fullName: 'University of Massachusetts, Amherst' },
  { shortName: 'UMAINE', fullName: 'University of Maine' },
];

const bestValue = [
  { shortName: 'MIT', fullName: 'Massachusetts Institute of Technology' },
  { shortName: 'STANFORD', fullName: 'Stanford University' },
  { shortName: 'PRINCETON', fullName: 'Princeton University' },
  { shortName: 'UMICH', fullName: 'University of Michigan - Ann Arbor' },
  { shortName: 'DUKE', fullName: 'Duke University' },
];

const School = ({ latestDecisionThreads, trendingColleges }) => {
  return (
    <div className="bg-#F3F5F5">
      <div className="max-w-6xl m-auto py-10">
        <Header />
        <div className="grid grid-cols-1 md:grid-cols-2 md:gap-9">
          <div>
            <div className="overflow-auto w-full md:w-auto pl-9 md:pl-0">
              <div className="grid grid-cols-[repeat(4,250px)] md:grid-cols-2 gap-6  ">
                <div
                  className="p-6 md:pl-7 md:py-6 rounded-md md:rounded-tr-[80px] shadow-lg bg-cover"
                  style={{ backgroundImage: 'url(/static/IndexPage/school-bg-1.svg)' }}>
                  <div className="text-#FC9F4A text-sm font-bold mb-2 ml-4">最受欢迎</div>
                  <ol className="list-decimal md:ml-4 text-xs">
                    {_.slice(trendingColleges, 0, 5).map(item => (
                      <li className="truncate" key={item.short_name}>
                        <Link href={`/university/${item.short_name}`}>
                          <a className="text-#5E5E5E hover:text-primary">{item.full_name}</a>
                        </Link>
                      </li>
                    ))}
                  </ol>
                </div>
                <div
                  className="p-6 md:pl-7 md:py-6 rounded-md md:rounded-tr-[80px] shadow-lg bg-cover"
                  style={{ backgroundImage: 'url(/static/IndexPage/school-bg-2.svg)' }}>
                  <div className="text-#21A318 text-sm font-bold mb-2 ml-4">吃得最好</div>
                  <ol className="list-decimal md:ml-4 text-xs">
                    {bestFood.map(({ shortName, fullName }) => (
                      <li className="truncate" key={shortName}>
                        <Link href={`/university/${shortName}`}>
                          <a className="text-#5E5E5E hover:text-primary">{fullName}</a>
                        </Link>
                      </li>
                    ))}
                  </ol>
                </div>
                <div
                  className="p-6 md:pl-7 md:py-6 rounded-md md:rounded-tr-[80px] shadow-lg bg-cover"
                  style={{ backgroundImage: 'url(/static/IndexPage/school-bg-3.svg)' }}>
                  <div className="text-#CB4141 text-sm font-bold mb-2 ml-4">最安全校园</div>
                  <ol className="list-decimal md:ml-4 text-xs">
                    {bestSafety.map(({ shortName, fullName }) => (
                      <li className="truncate" key={shortName}>
                        <Link href={`/university/${shortName}`}>
                          <a className="text-#5E5E5E hover:text-primary">{fullName}</a>
                        </Link>
                      </li>
                    ))}
                  </ol>
                </div>
                <div
                  className="p-6 md:pl-7 md:py-6 rounded-md md:rounded-tr-[80px] shadow-lg bg-cover"
                  style={{ backgroundImage: 'url(/static/IndexPage/school-bg-4.svg)' }}>
                  <div className="text-primary text-sm font-bold mb-2 ml-4">最佳性价比</div>
                  <ol className="list-decimal md:ml-4 text-xs">
                    {bestValue.map(({ shortName, fullName }) => (
                      <li className="truncate" key={shortName}>
                        <Link href={`/university/${shortName}`}>
                          <a className="text-#5E5E5E hover:text-primary">{fullName}</a>
                        </Link>
                      </li>
                    ))}
                  </ol>
                </div>
              </div>
            </div>
            <Link href="/my/decision">
              <a className=" text-xl font-bold bg-#FFF4DF text-#FC9F4A h-[78px] md:h-12 flex items-center justify-center mt-6 shadow-4 rounded mx-8 md:mx-0">
                犹豫不决？ 全方位
                <br className=" md:hidden" />
                比较未来留学生活
              </a>
            </Link>
          </div>

          <div className="mt-7 md:my-0 max-w-[100vw]">
            <Discuss threads={latestDecisionThreads} fid={71} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default School;
