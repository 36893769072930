import React from 'react';
import { Button, Carousel } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import _ from 'lodash';

import { getAvatarAddress, bbsEndpoint } from '~/utils/Constants';
import { getItemValue } from '~/components/FormItems/Utils';
import useResponsive from '~/hooks/useResponsive';

const Header = () => (
  <div className="w-full text-white text-center mb-5">
    <strong className="text-2xl font-bold">经验分享</strong>
  </div>
);

const Experience = ({ threads }) => {
  const { lg } = useResponsive();
  return (
    <div className="bg-contain bg-no-repeat p-6" style={{ background: 'url(/static/map_bg.png), #BDCCCF' }}>
      <div className="experience-carousel relative max-w-6xl m-auto">
        <Header />
        <Carousel
          dots={{ dotsClass: 'rounded-full' }}
          autoplay
          arrows
          prevArrow={<LeftOutlined className="text-lg" />}
          nextArrow={<RightOutlined className="text-lg" />}>
          {threads &&
            _.chunk(_.shuffle(threads), lg ? 3 : 1).map((group, i) => (
              <div className="grid grid-cols-1 lg:grid-cols-3 gap-3" key={i}>
                {group.map(({ subject, author, authorid, tid, summary, options }) => {
                  const snippetOptions = _.filter([
                    options?.outname,
                    getItemValue('planmajor', options.planmajor),
                    getItemValue('plandegree', options.plandegree),
                    getItemValue('result', options.result),
                  ]);
                  return (
                    <a href={bbsEndpoint.THREAD(tid)} target="_blank" className=" text-current" key={tid}>
                      <div className="bg-white rounded shadow-md p-5 mb-4">
                        <div className="flex py-2 border-b border-b-##DCDCDC">
                          <img src={getAvatarAddress(authorid)} className="w-8 h-8 object-contain mr-2" />
                          <div>
                            <div className="text-sm leading-tight break-words">{subject}</div>
                            <div className="text-primary leading-tight">{author}</div>
                          </div>
                        </div>
                        <div className="text-#FC9F4A my-1 break-words">{snippetOptions.join(' | ')}</div>
                        <div className="my-1 break-words fade">{summary}</div>
                      </div>
                    </a>
                  );
                })}
              </div>
            ))}
        </Carousel>
        <div className="flex justify-center gap-x-6 mt-10">
          <Button
            className="text-#FFA500 bg-#FFF4DF border-0"
            href="https://instant.1point3acres.cn/forum/27"
            target="_blank">
            更多经验分享
          </Button>
        </div>
      </div>
      <style jsx global>{`
        .ant-carousel .slick-dots-bottom {
          bottom: -20px !important;
          height: 16px;
          z-index: 0 !important;
        }

        .ant-carousel .slick-dots li.slick-active {
          width: 16px !important;
        }

        .experience-carousel .ant-carousel .slick-dots li button,
        .experience-carousel .ant-carousel .slick-dots li.slick-active button {
          width: 10px !important;
          height: 10px !important;
          border-radius: 10px !important;
        }

        .experience-carousel .ant-carousel .slick-prev,
        .experience-carousel .ant-carousel .slick-next,
        .experience-carousel .ant-carousel .slick-prev:hover,
        .experience-carousel .ant-carousel .slick-next:hover {
          font-size: 20px !important;
          color: white !important;
          z-index: 10 !important;
          top: unset !important;
          bottom: -20px !important;
        }

        .experience-carousel .ant-carousel .slick-prev,
        .experience-carousel .ant-carousel .slick-prev:hover {
          left: calc(50% - ${(16 * (threads?.length || 0)) / (lg ? 6 : 2) + 50}px) !important;
        }

        .experience-carousel .ant-carousel .slick-next,
        .experience-carousel .ant-carousel .slick-next:hover {
          left: calc(50% + ${(16 * (threads?.length || 0)) / (lg ? 6 : 2) + 23}px) !important;
        }
      `}</style>
    </div>
  );
};

export default Experience;
